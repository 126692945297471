import menu, { closeMenu, openMenu, openSubMenu } from "./components/menu";

import Fsbox from "fslightbox";
document.addEventListener("DOMContentLoad", () => {
  const lightbox = new Fsbox();
  lightbox.props.sources = ["first-lightbox", "example-video.mp4"];
  lightbox["first-lightbox"].props.maxImageDimensions = {
    width: "100%",
    height: "auto",
  };
  lightbox.props.onInit = () => console.log("Lightbox initialized!");
});

menu.button.open.addEventListener("click", openMenu);
menu.button.close.addEventListener("click", closeMenu);
menu.overlay.addEventListener("click", closeMenu);
menu.dropDowns.forEach((dropDown) => {
  dropDown.addEventListener("click", openSubMenu);
});

// TODO adicionar fixed no cabeçãlho quando rolar a tela
// if (window.screen.width > 992) {
//   const header = document.querySelector('.header');
//   const headerClass = 'header__fixed';

//   window.addEventListener('scroll', function() {
//     if (window.scrollY >= 180 && header.classList.contains(headerClass)) {
//       header.classList.add(headerClass);
//     }

//     if (window.scrollY < 60 && !header.classList.contains(headerClass)) {
//       header.classList.remove(headerClass);
//     }
//   });
// }

const menuNav = {
  header: document.querySelector(".header__nav"),
  simulate: document.querySelector(".header__simulate"),
  menu: {
    button: document.querySelector(".nav__content-box"),
    icon: document.querySelector(".nav__content-box .fas"),
  },
  navList: document.querySelector(".nav__list"),
  class: { active: "active", bars: "fa-bars", xMark: "fa-x", col: "col-lg-9", halfCol: "col-lg-3", none: "d-none" }
}

function toggleMenu() {
  menuNav.navList.classList.toggle(menuNav.class.active);
  menuNav.menu.icon.classList.toggle(menuNav.class.bars);
  menuNav.menu.icon.classList.toggle(menuNav.class.xMark);
  menuNav.header.classList.toggle(menuNav.class.col);
  menuNav.header.classList.toggle(menuNav.class.halfCol);

  menuNav.navList.classList.contains("active")
    ? menuNav.simulate.classList.toggle(menuNav.class.none)
    : menuNav.simulate.classList.toggle(menuNav.class.none)

  sessionStorage.setItem("navListClass", menuNav.navList.classList);
  sessionStorage.setItem("menuIconClass", menuNav.menu.icon.classList);
  sessionStorage.setItem("headerClass", menuNav.header.classList);
  sessionStorage.setItem("simulateClass", menuNav.simulate.classList);
}

menuNav.menu.button.addEventListener("click", toggleMenu);

const storageMenu = {
  nav: sessionStorage.getItem("navListClass"),
  menu: sessionStorage.getItem("menuIconClass"),
  header: sessionStorage.getItem("headerClass"),
  simulate: sessionStorage.getItem("simulateClass"),
}

if (storageMenu.nav && storageMenu.menu && storageMenu.header && storageMenu.simulate) {
  menuNav.navList.classList = storageMenu.nav;
  menuNav.menu.icon.classList = storageMenu.menu;
  menuNav.header.classList = storageMenu.header;
  menuNav.simulate.classList = storageMenu.simulate;
}